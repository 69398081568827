<template>
  <!--begin::Dashboard-->
  <div class="p-5 container"  v-if="listData && listData.length > 0">
    <router-link
      v-for="(item, index) in listData"
      :key="index"
      :to="'/qaboard/' + item.id"
    >
      <div
        class="my-3"
        style="background: #F7F7F7;border-radius: 10px;padding: 15px;border: 1px solid #E9E9EF;"
      >
        <p
          style="font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #333333;"
        >
          {{ item.title }}
        </p>
        <div
          style="display: flex;justify-content: space-between;align-items: center;"
        >
          <div
            style="display: flex;
                    justify-content: flex-start;
                    flex-direction: row;
                    align-items: center;"
          >
            <div
              class="symbol symbol-50px symbol-circle"
              style="border: 1px solid #a1a1a1;"
            >
              <img
                :src="
                  item.authorProfile
                    ? imageUrl + item.authorProfile.imageProfile
                    : ''
                "
                alt=""
                style="width: 50px;height: 50px;object-fit: cover;"
                @error="setAltImg"
              />
            </div>
            <div class="mx-5">
              <p style="color:#000000" class="text-category">
                {{
                  item.authorProfile
                    ? item.authorProfile.firstName +
                      " " +
                      item.authorProfile.lastName
                    : "-"
                }}
              </p>
              <p style="color:#9F9F9F" class="text-category">{{ formatDateTime(item.createDate) }}</p>
            </div>
          </div>
          <div style="color:#9F9F9F" class="text-category">
            ข้อความล่าสุด |{{ formatDate(item.updateDate) }}
            <img  src="media/icon-app/bubble.svg" alt="">
            {{ item.comment}}
          </div>
        </div>
      </div>
    </router-link>
 <div
      v-if="listData && listData.length > 0"
      style="display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: row;">
      <b class="text-category" style="color:#9999D3"
        >จำนวน {{ listData && listData.totalElements }} รายการ</b
      >
      <ul class="pagination">
        <li :class="'page-item previous ' + (page == 0 ? 'disabled' : '')">
          <button type="button" @click="backPage()" class="page-link">
            <i class="previous"></i>
          </button>
        </li>
        <div v-for="(item, index) in totalPage" :key="index">
          <li :class="'page-item ' + (index == page ? 'active' : '')">
            <button type="button" @click="changePage(item)" class="page-link">
              {{ item }}
            </button>
          </li>
        </div>
        <li
          :class="'page-item next ' + (page + 1 == totalPage ? 'disabled' : '')"
        >
          <button type="button" @click="nextPage()" class="page-link">
            <i class="next"></i>
          </button>
        </li>
      </ul>
    </div>
    
  </div>
   <div class="p-5 container text-center mb-5" v-else>
       <img src="media/image-app/no-data.svg" alt="" style="height:300px"/>
   </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
const video = ref();
const canvas = ref();
const captures: any = ref([]);
const isCameraOpen = ref(false);
const listData = ref();
const limit = ref(10);
const page = ref(0);
const pages = ref([]);
const orderBy = ref(1);
const sortBy = ref(2);
const categories = ref([]);
const totalPage = ref(0);
const totalElements = ref();
const filterForm = ref({
  category: "",
  status: "",
  orderBy: "",
});
export default defineComponent({
  name: "qaboard",
  components: {},
  data() {
    return {
      categoryList: [],
      imageUrl: process.env.VUE_APP_API_URL,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("ถามตอบ", []);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getWebboard?sizeContents=" +
            limit.value +
            "&page=" +
            page.value +
            "&orderBy=" +
            orderBy.value +
            "&sort=" +
            sortBy.value,
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token") ? localStorage.getItem("id_token") :localStorage.getItem("tokenGuest")
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content;
          listData.value["totalElements"] = response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
          const number: any = [];
          for (let index = 1; index <= totalPage.value; index++) {
            number.push(index);
          }
          pages.value = number;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      page.value = pageEvent - 1;
      getDataList();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getDataList();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getDataList();
    };
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      getDataList,
      changePage,
      backPage,
      nextPage,
      listData,
      limit,
      page,
      orderBy,
      sortBy,
      categories,
      totalPage,
      filterForm,
      pages,
      totalElements,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/avatars/blank.png";
    },
    check1(page) {
      if (page == 1) {
        return page - 1;
      }
      if (page > 1) {
        if (page == totalPage.value - 1) {
          return page - 4;
        } else if (page == totalPage.value - 2) {
          return page - 3;
        }
        {
          return page - 2;
        }
      }
    },
    check2(page) {
      if (page == 0) {
        return page + 5;
      }
      if (page == 1) {
        return page + 4;
      }
      if (page > 1) {
        return page + 3;
      }
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
</script>
