
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
const video = ref();
const canvas = ref();
const captures: any = ref([]);
const isCameraOpen = ref(false);
const listData = ref();
const limit = ref(10);
const page = ref(0);
const pages = ref([]);
const orderBy = ref(1);
const sortBy = ref(2);
const categories = ref([]);
const totalPage = ref(0);
const totalElements = ref();
const filterForm = ref({
  category: "",
  status: "",
  orderBy: "",
});
export default defineComponent({
  name: "qaboard",
  components: {},
  data() {
    return {
      categoryList: [],
      imageUrl: process.env.VUE_APP_API_URL,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("ถามตอบ", []);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getWebboard?sizeContents=" +
            limit.value +
            "&page=" +
            page.value +
            "&orderBy=" +
            orderBy.value +
            "&sort=" +
            sortBy.value,
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token") ? localStorage.getItem("id_token") :localStorage.getItem("tokenGuest")
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content;
          listData.value["totalElements"] = response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
          const number: any = [];
          for (let index = 1; index <= totalPage.value; index++) {
            number.push(index);
          }
          pages.value = number;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      page.value = pageEvent - 1;
      getDataList();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getDataList();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getDataList();
    };
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      getDataList,
      changePage,
      backPage,
      nextPage,
      listData,
      limit,
      page,
      orderBy,
      sortBy,
      categories,
      totalPage,
      filterForm,
      pages,
      totalElements,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/avatars/blank.png";
    },
    check1(page) {
      if (page == 1) {
        return page - 1;
      }
      if (page > 1) {
        if (page == totalPage.value - 1) {
          return page - 4;
        } else if (page == totalPage.value - 2) {
          return page - 3;
        }
        {
          return page - 2;
        }
      }
    },
    check2(page) {
      if (page == 0) {
        return page + 5;
      }
      if (page == 1) {
        return page + 4;
      }
      if (page > 1) {
        return page + 3;
      }
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
  },
});
